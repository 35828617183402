<template>
  <div class="feature-demo">
    <v-container class="fixed-width">
      <v-row class="spacing6">
        <v-col md="6" class="pa-6">
          <!-- <main-title :align="isMobile ? 'center' : 'left'">
            <strong> Features </strong>
          </main-title> -->
          <main-title
            :text="$t('feature.title')"
            :caption="$t('feature.caption')"
            :align="isMobile ? 'center' : 'left'"
          ></main-title>

          <div class="mt-0">
            <v-list three-line style="background-color: #F6F6FB">
              <template>
                <v-list-item v-for="(item, index) in featureList" :key="index">
                  <v-list-item-avatar>
                    <v-img :src="item.img"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("hostingLanding.mainfeature_" + item.title) }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption">
                      {{ $t("hostingLanding.mainfeature_" + item.desc) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </div>
        </v-col>
        <v-col md="6" cols="12" class="pa-6">
          <v-card class="mx-auto" elevation="1" width="200">
            <video
              class="video-module--videoStyle--bYeEO"
              playsinline=""
              autoplay=""
              loop=""
              
              width="200"
              muted=""
              poster="/static/017923948ee4d5b169e22e256e258dee/gb_ecom_pis_demo_cover.png"
              controlslist="nodownload"
              src="https://taybullpay-public.s3.eu-west-2.amazonaws.com/send-money-watermark.mp4"
            ></video>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss" scoped>
/* @import "./feature-style.scss"; */
</style>


<script>
//import MainTitle from "../Title";
import Title from "../FeatureTitle";
export default {
  data() {
    return {
      featureList: [
        {
          img: "/assets/images/hosting/feature1.png",
          title: "titleitem1",
          desc: "descitem1",
        },
        {
          img: "/assets/images/hosting/feature2.png",
          title: "titleitem2",
          desc: "descitem2",
        },
        {
          img: "/assets/images/hosting/feature3.png",
          title: "titleitem3",
          desc: "descitem3",
        },
      ],
    };
  },
  components: {
    "main-title": Title,

    // "main-title": Title,
  },
  computed: {
    isMobile() {
      const smDown = this.$store.state.breakpoints.smDown;
      return smDown.indexOf(this.$mq) > -1;
    },
  },
};
</script>