<template>
  <div class="root">
   <!--  <v-container class="fixed-width">
      <v-row class="spacing6">
        <v-col md="6" class="pa-6">
          <Title :align="isMobile ? 'center' : 'left'">
            <strong> Features </strong>
          </Title>
        </v-col>
        <v-col md="6" cols="12" class="pa-6">
            <v-card class="mx-auto" elevation="1" max-width="300">
              <video
                class="video-module--videoStyle--bYeEO"
                playsinline=""
                autoplay=""
                loop=""
                width="300"
                
                muted=""
                poster="/static/017923948ee4d5b169e22e256e258dee/gb_ecom_pis_demo_cover.png"
                controlslist="nodownload"
                src="https://taybullpay-public.s3.eu-west-2.amazonaws.com/send-money-watermark.mp4"
              ></video>
            </v-card>
        </v-col>
      </v-row>
    </v-container> -->
    <feature-demo/>
    <!-- <main-feature /> -->
    <more-feature />
  </div>
</template>

<style lang="scss" scoped>
@import "./feature-style.scss";
</style>

<script>
//import MainFeature from './MainFeature'
import MoreFeature from "./MoreFeature";
import FeatureDemo from './FeatureDemo';
//import Feature from "@tenant/components/Feature";
//import Title from "../Title";
export default {
  components: {
    //Title,
   // MainFeature,
    MoreFeature,
    FeatureDemo
  },
  computed: {
    isMobile() {
      const smDown = this.$store.state.breakpoints.smDown;
      return smDown.indexOf(this.$mq) > -1;
    },
  },
};
</script>
