<template>
  <div
    class="banner-wrapper send-money send-money-page"
    style="background-color: var(--v-header-bg)"
  >
    <v-container>
      <!-- -----------------------------------------------
            Start Banner
        ----------------------------------------------- -->
      <v-row justify="center">
        <v-col
          cols="12"
          md="7"
          lg="6"
          v-if="!isMobile"
          class="align-center side-text"
        >
          <send-money-web-title-content />
        </v-col>
        <v-col cols="12" md="5" lg="6" class="pt-3 align-center">
          <!--  <h2
            v-if="isMobile"
            class="banner-title font-weight-bold text-center pb-3"
          >
            Fast Transfers. Low Fees.
          </h2> -->
          <!--   <div class="text-center"> -->
          <!--   <v-btn rounded color="primary" class="pa-1 ma-1" @click="internationalTransfer" dark> Send Money </v-btn>
            <v-btn rounded color="orange" class="pa-1 ma-1" @click="localTransfer" dark> Local Transfer </v-btn> -->
          <!--          <v-tabs
            v-model="isLocalTransfer"
            grow>
              <v-tab>Send Money</v-tab>
              <v-tab>Local Transfer</v-tab>
            </v-tabs> -->
          <!-- </div> -->
          <!-- v-if="!isLocalTransfer" -->
          <converter
            :key="sendMoneyConverterKey"
            :show-send-button="true"
            @send="openSendMoneyStepper"
          />
          <!-- <local-transfer v-else/> -->
          <!--           <div v-if="isMobile" class="mt-0 nav">
            <ul class="text-center mx-auto">
              <li><v-btn to="/buy-cash-power">Cash Power</v-btn></li>
              <li><v-btn to="/topup-airtime">Mobile Top Up</v-btn></li>
              <li>
                <v-btn to="/"
                  ><v-badge color="success" floating content="Coming Soon!">
                    <v-icon right size="x-small"></v-icon>
                  </v-badge>
                  Mobile Data</v-btn
                >
              </li>
            </ul>
          </div> -->
         <!--  <div v-if="isMobile" class="mt-3 nav">
            <ul class="text-center mx-auto" style="padding-inline-start: 0">
              <li style="margin-bottom: 5px">
                <v-btn to="/buy-cash-power">Cash Power</v-btn>
              </li>
              <li style="margin-bottom: 5px">
                <v-btn to="/topup-airtime">Mobile Top Up</v-btn>
              </li> -->
              <!-- <li style="margin-bottom: 5px">
                <v-btn to="/" disabled> Mobile Data </v-btn>
                
              </li> -->
         <!--    </ul>
          </div> -->
        </v-col>
        <v-col class="mt-10 mb-5" cols="12" xs="12">
          <Demo />
        </v-col>
        <v-col cols="12" xs="12">
          <Feature />
        </v-col>
        <!-- <v-col cols="12" xs="12">
          <Feature />
        </v-col> -->
        <!-- exchange listings -->
        <!--  <v-col cols="12" xs="12">
          <Exchange />
        </v-col> -->

        <v-col cols="12" xs="12">
          <FAQ />
        </v-col>
        <!-- Testimonials -->
        <!--    <v-col cols="12" xs="12">
          <Testimonials/>
        </v-col> -->
      </v-row>

      <!-- -----------------------------------------------
            End Banner
        ----------------------------------------------- -->
    </v-container>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
//import FAQ from "@/components/Faq";
import FAQ from "@tenant/components/Faq";
import Demo from "@tenant/components/Demo/Demo.vue";
import Feature from "@tenant/components/Feature";
import SendMoneyWebTitleContent from "@tenant/content/send-money-web-title.vue";
const sendMoneyNamespace = createNamespacedHelpers("sendmoney");
//import Exchange from "@/components/Exchange/ExchangeListing";
//import Testimonials from "@/components/testimonial/Testimonials";
export default {
  name: "SendMoneyView",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Send Money to Gambia, Buy NAWEC Cash Power, Mobile Top-Up Online",
    // all titles will be injected into this template
  },
  components: {
    Converter: () => import("@/components/converter/send-money-converter"),
    ///LocalTransfer: () => import("@/components/converter/local-money-transfer-converter"),
    //Converter:() =>import("@tenant/components/converter/send-money-converter.vue"),
    FAQ,
    Feature,
    SendMoneyWebTitleContent,
    //Exchange,
    //Testimonials,
    Demo,
  },
  data() {
    return {
      isLocalTransfer: false,
    };
  },
  computed: {
    ...sendMoneyNamespace.mapState({
      sendMoneyConverterKey: (state) => state.key,
    }),
    isMobile() {
      const smDown = this.$store.state.breakpoints.smDown;
      return smDown.indexOf(this.$mq) > -1;
    },
  },
  methods: {
    ...sendMoneyNamespace.mapMutations(["mSendMoney", "mSetKey"]),
    openSendMoneyStepper(params) {
      this.mSendMoney(params);
      // this.mSendMoney()
      console.log("stepper opened");
    },

    localTransfer() {
      this.isLocalTransfer = true;
    },
    internationalTransfer() {
      this.isLocalTransfer = false;
    },
  },
  mounted() {
    this.mSetKey();
  },
};
</script>

<style scoped>
@import "@tenant/views/public/send-money.scss";
</style>
