<template>
  <div class="root">
    <v-container class="fixed-width">
      <v-row class="spacing6">
        <v-col md="6" class="pa-6">
          <title-main :align="isMobile ? 'center' : 'left'">
            <strong> FAQ </strong>
          </title-main>
          <p
            class="text use-text-subtitle2"
            :class="[isMobile ? 'text-center' : 'text-left']"
          >
            {{ $t("mobileLanding.faq_subtitle") }}
          </p>
          <div class="illustration">
            <svg class="deco-primary">
              <use xlink:href="/images/mobile/deco-feature.svg#main" />
            </svg>
            <parallax-extra-small />
            <img src="/assets/images/mobile/faq.png" alt="illustration" />
          </div>
        </v-col>
        <v-col md="6" cols="12" class="pa-6">
          <div class="accordion">
            <v-expansion-panels v-model="panel" active-class="expanded">
              <v-expansion-panel
                v-for="(item, index) in faqData"
                :key="index"
                class="paper"
              >
                <v-expansion-panel-header class="content">
                  <span class="heading">
                    {{ item.q }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="detail">
                  <span>
                    {{ item.a }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="paper">
                <v-expansion-panel-header class="content">
                  <span class="heading">
                    How do I make an international payment transfer?
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="detail">
                  <span>It’s very straightforward</span>
                  <ol type="1">
                    <li>Sign up for a TaybullPay account</li>
                    <li>Select the country and the amount you want to send</li>
                    <li>Enter the recipient’s personal and bank details</li>
                    <li>Pay for the transaction</li>
                  </ol>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
@import "faq-style.scss";
</style>

<script>
import ParallaxExtraSmall from "../Parallax/ExtraSmall";
import Title from "../Title";

const faqData = [
  {
    q: "What information is needed for a TaybullPay money transfer?",
    a: "TaybullPay accepts payment by bank account or debit/credit card. A government-issued ID may be required to create your account or if sending a transfer. The recipient's name and contact information will also be required.",
  },
/*   {
    q: "How do I make an international payment transfer?",

    a: `It’s very straightforward
1. Sign up for a TaybullPay account
2. Select the country and the amount you want to send
3. Enter the recipient’s personal and bank details
4. Pay for the transaction
` 

  }, */
  {
    q: " How long do international payment transfers take?",
    a: "This can vary but we try to ensure your payment is made in the shortest possible time. ",
  },
  {
    q: "How much will an international payment transfer cost?",
    a: "We are very transparent with our fee structure so you can easily see how much you are being charged. ",
  },
  {
    q: "What is the maximum amount I can send?",
    a: "This depends on a number of factors such as where you’re sending to and from, local transaction limits etc",
  },
];

export default {
  components: {
    ParallaxExtraSmall,
    "title-main": Title,
  },
  data() {
    return {
      panel: 0,
      faqData: faqData,
    };
  },
  computed: {
    isMobile() {
      const smDown = this.$store.state.breakpoints.smDown;
      return smDown.indexOf(this.$mq) > -1;
    },
  },
};
</script>
