<template>
  <!-- <div class="main-feature">
    <v-container fixed>
      <main-title text="Send money to the Gambia Easily!" align="center" />
      <v-row class="" align="center" justify="center">
        <v-col
          v-for="(item, index) in featureList"
          :key="index"
          md="3"
          cols="12"
          class=" mx-auto"
        >
          <div class="item">
            <v-card elevation="5" class="mx-5 my-5" :width="isMobile ? '300' : '200'" :height="isMobile? '650': '450'">
              <v-img class="" :height="isMobile? '650': '450'" :width="isMobile ? '300' : '200'" :src="item.img" alt="item.title"></v-img>
            </v-card>
          
              <h3 class=" mb-1 font-weight-medium subtitle-1 title-text">
                {{ item.title }}
              </h3>
              <p class="caption desc-text ">
                {{ item.desc }}
              </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div> -->

    <div class="">
    <v-container fixed>
      <main-title
        text="Send Money to The Gambia Easily!"
        caption=""
        align="center"
         style="margin-bottom:-3%"
     
      />
      <v-row class=" grid" >
        <v-col
          v-for="(item, index) in featureList"
          :key="index"
          md="3"
          cols="12"
          class=" text-center"
          :align="isMobile ? 'center': ''"
        >
          <div class="item">
            <!-- <figure>
              <img width="200" height="300" :src="item.img" :alt="item.title" />
            </figure> -->
             <!-- <v-card  > -->
              <img class="" height="500"  width="230" :src="item.img" :alt="item.title"/>
            <!-- </v-card> -->
            <h6 class="font-weight-medium title-text">
              {{ item.title}}
            </h6>
            <p class="subtitle-2  desc-text mt-0" style="margin-bottom:-11%">
              {{ item.desc }}
            </p>
          <!--   <v-card-text class="caption mt-0">
              {{ item.desc }}
            </v-card-text> -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
@import "./demo-style.scss";
</style>

<script>
import Title from "../FeatureTitle";

export default {
  components: {
    "main-title": Title,
  },
  data() {
    return {
      featureList: [
        {
          img: "/assets/images/sendmoney-screen-1.png",
          title: "Select 'Send Money' from the home screen",
          desc: "To start a transfer, select send money from the the home screen to get started ",
        },
        {
          img: "/assets/images/sendmoney-screen-2.png",
          title: "Add or Select a benficiary",
          desc: "On the next screen, click 'add beneficiary' to add a new beneficiary or select and an existing one from the list.",
        },
        {
          img: "/assets/images/sendmoney-screen-3.png",
          title: "Enter the amount you wish to send",
          desc: "Enter the amount you wish to send and click 'Send Money' to proceed.",
        },
        {
          img: "/assets/images/sendmoney-screen-4.png",
          title: "Choose your payment method",
          desc: "Select the payment method from the available payment options and click on 'Pay'.",
        },
      ],
    };
  },
  computed:{
       isMobile() {
      const smDown = this.$store.state.breakpoints.smDown;
      return smDown.indexOf(this.$mq) > -1;
    },
  }
};
</script>
